import { Box, Typography } from '@mui/material';
import qs from 'qs';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { CourseManagerTypes, useAppContext } from '@/Context';

import FigmaDesignTokens from '../../design/design-tokens.json';
import { useCurrentLanguage } from '../../Hooks/useCurrentLanguage';

const useStyles = makeStyles()(() => ({
  background: {
    height: '100vh',
    width: '100vw',
    backgroundImage: `linear-gradient(170deg, ${FigmaDesignTokens.Blue[200]} 29%, ${FigmaDesignTokens.Blue[800]} 109%)`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  partnerNameWrapper: {
    borderRadius: 33.5,
    boxShadow: '0 15px 10px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: FigmaDesignTokens.White,
    padding: '20px 44px',
    marginTop: 27,
  },
  partnerName: {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.4,
    color: FigmaDesignTokens.Blue[950],
    textTransform: 'uppercase',
  },
}));

export const SwitchPartner = () => {
  const { classes } = useStyles();
  const { dispatch } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (queryParams.publicId) {
      setTimeout(() => {
        navigate(`/${currentLanguage}/dashboard`);
      }, 3000);
      return () => {
        localStorage.setItem('publicId', queryParams.publicId as string);
        sessionStorage.removeItem('ResourceAuth');
        dispatch({ type: CourseManagerTypes.SET_COURSE, payload: { course: null } });
        dispatch({ type: CourseManagerTypes.SET_EVENTS, payload: { events: null } });
      };
    }
  }, [dispatch, navigate, currentLanguage, queryParams]);

  return (
    <Box className={classes.background}>
      <Box className={classes.partnerNameWrapper}>
        <Typography variant='body1' className={classes.partnerName}>
          {queryParams.name as string}
        </Typography>
      </Box>
    </Box>
  );
};
