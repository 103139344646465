import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { Login } from './Login';
import { DataFetchingStatus, AuthStateTypes } from '@/Types';

export const LoginExtraReducers = (builder: ActionReducerMapBuilder<AuthStateTypes>) => {
  builder.addCase(Login.pending, state => {
    state.fetchingStatus = DataFetchingStatus.Fetching;
  });
  builder.addCase(Login.fulfilled, (state, action) => {
    state.fetchingStatus = DataFetchingStatus.FulFilled;
    state.isAuthenticated = true;
    state.cognitoUser = action.payload;
  });
  builder.addCase(Login.rejected, state => {
    state.fetchingStatus = DataFetchingStatus.HasError;
    state.isAuthenticated = false;
    state.credentials = null;
  });
};
