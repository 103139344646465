import React, { useContext } from 'react';
import ReactRouterPrompt from 'react-router-prompt';

import { AppContext } from '@/Context';

import { LeavingGuardModal } from './LeavingGuardModal';

export const RouteLeavingGuard = () => {
  const { state } = useContext(AppContext);
  const showDialog = state.confirmationDialog.show;

  return (
    <ReactRouterPrompt when={showDialog}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && <LeavingGuardModal onConfirm={onConfirm} onCancel={onCancel} />
      }
    </ReactRouterPrompt>
  );
};
