import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { PartnerCoursesServiceFactory } from '@/Services';
import { Partner } from '@/Types';

type useGetPartnerCoursesQueryProps = {
  casPublicId: Partner['casPublicId'];
};

const useGetPartnerCoursesQuery = ({ casPublicId }: useGetPartnerCoursesQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['getPartnerCourses'],
    async () => await partnerCoursesService.getCourses(casPublicId),
    {
      cacheTime: 0,
    },
  );

  return { ...queryResult, data: queryResult.data?.data.result };
};

export default useGetPartnerCoursesQuery;
