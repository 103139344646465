import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { PartnerCoursesServiceFactory } from '@/Services';
import { Partner } from '@/Types';

type useGetInactiveCoursesQueryProps = {
  casPublicId: Partner['casPublicId'];
};

const useGetInactiveCoursesQuery = ({ casPublicId }: useGetInactiveCoursesQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['getInactivePartnerCourses'],
    async () => await partnerCoursesService.getPastCourses(casPublicId),
    {
      cacheTime: 0,
    },
  );

  return { ...queryResult, data: queryResult.data?.data.result };
};

export default useGetInactiveCoursesQuery;
