import { FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  GalleryForm,
  Header,
  LogoForm,
  PreviewModal,
  SpinnerLoader,
  TabPanelNavigation,
  VideoForm,
} from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import usePhotoAndVideoBreadcrumbs from '@/Hooks/Breadcrumbs/usePhotoAndVideoBreadcrumbs';
import { getPhotoAndVideoTabIdx, getPhotoAndVideoTabName } from '@/Mappers';
import {
  useCurrentPartnerIdQuery,
  useCurrentUserInfoQuery,
  useGetAssetsQuery,
  useGetPartnerQuery,
} from '@/Queries';
import InfoIcon from '@/Static/Icons/info_delay_icon.svg';
import { GalleryAsset, LogoAssetProps, VideoAsset } from '@/Types';
import {
  CrazyUseRefCallbackStuffTypes,
  isGalleryFormRef,
  isLogoAssetRef,
  isVideoFormRef,
} from '@/Utils/formRefTypeguards';
import handleCatchError from '@/Utils/handleCatchError';

import { CtaFooter } from '../../App/Shared/Footer/CtaFooter';
import { DelayModal } from '../../App/Shared/Modal/DelayModal';
import { FormSnackbar } from '../../App/Shared/Notification/Components/FormSnackbar';
import { useCurrentLanguage } from '../../Hooks/useCurrentLanguage';

const PhotoAndVideo = () => {
  const intl = useIntl();
  const { tab = '' } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const [currentTab, setCurrentTab] = useState(getPhotoAndVideoTabIdx(tab));
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();
  const [logoFormRef, setLogoFormRef] = useState<FormikProps<LogoAssetProps>>();
  const [galleryFormRef, setGalleryFormRef] = useState<FormikProps<GalleryAsset>>();
  const [videoFormRef, setVideoFormRef] = useState<FormikProps<VideoAsset>>();
  const [openToastMessage, setOpenToastMessage] = useState(false);

  const [primaryCtaDisabled, setPrimaryCtaDisabled] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  const { data: partnerData = null, isLoading: isLoadingPartnerData } = useGetPartnerQuery();
  const { data: userAttributes, isLoading: isLoadingUserAttributes } = useCurrentUserInfoQuery();
  const { data: casPublicId, isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();
  const {
    data: assets,
    isLoading: isLoadingAssets,
    refetch: refetchAssets,
  } = useGetAssetsQuery({ casPublicId });

  const handlePrimaryCtaDisabled = useCallback((value: boolean) => {
    setPrimaryCtaDisabled(value);
  }, []);

  const handleCloseToastMessage = useCallback(() => {
    setOpenToastMessage(false);
  }, []);

  const handleOpenToastMessage = useCallback((openToastMessage: boolean) => {
    setOpenToastMessage(openToastMessage);
  }, []);

  const handleTabChange = useCallback(
    (_, newValue: number) => {
      const tabName = getPhotoAndVideoTabName(newValue);
      navigate(`/${currentLanguage}/foto-und-video/${tabName}`);
    },
    [currentLanguage, navigate],
  );

  const receiveRef = useCallback((ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => {
    if (tabNumber === 0 && isLogoAssetRef(ref) && ref.current) {
      setLogoFormRef(ref.current);
    }

    if (tabNumber === 1 && isGalleryFormRef(ref) && ref.current) {
      setGalleryFormRef(ref.current);
    }

    if (tabNumber === 2 && isVideoFormRef(ref) && ref.current) {
      setVideoFormRef(ref.current);
    }
  }, []);

  useEffect(() => {
    const newTab = getPhotoAndVideoTabIdx(tab);
    setCurrentTab(newTab);
  }, [tab, setCurrentTab]);

  const handleSubmit = useCallback(async () => {
    try {
      if (currentTab === 0 && logoFormRef) {
        await logoFormRef.submitForm();
      }

      if (currentTab === 1 && galleryFormRef) {
        await galleryFormRef.submitForm();
      }

      if (currentTab === 2 && videoFormRef) {
        await videoFormRef.submitForm();
      }
    } catch (error: unknown) {
      handleCatchError(error);
    }
  }, [currentTab, logoFormRef, galleryFormRef, videoFormRef]);

  const handleShowPreview = useCallback(() => {
    setShowPreview(true);
  }, []);

  const handleClosePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const currentBreadcrumbs = usePhotoAndVideoBreadcrumbs(currentTab);

  const isLoading =
    isLoadingUserAttributes || isLoadingCasPublicId || isLoadingPartnerData || isLoadingAssets;

  return (
    <>
      {isLoading && <SpinnerLoader isFullScreen />}
      <Header
        showLanguageSwitcher={currentTab === 2}
        callToActions={{
          primary: {
            title: intl.formatMessage({
              id: 'cta.save_and_publish',
              defaultMessage: 'Save & Publish',
            }),
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
          secondary: {
            title: intl.formatMessage({
              id: 'cta.preview_partner',
              defaultMessage: 'Preview Studio Profile',
            }),
            callback: handleShowPreview,
            disabled: false,
            variant: 'outlined',
          },
        }}
        handleTabChange={handleTabChange}
        currentTab={currentTab}
        header={intl.formatMessage({
          id: 'view.side_navigation.category.photo_and_video',
          defaultMessage: 'Image gallery',
        })}
        breadcrumbs={currentBreadcrumbs}
        tabs={[
          {
            label: intl.formatMessage({
              id: 'view.photo_and_video.logo',
              defaultMessage: 'Logo',
            }),
            disabled: false,
          },
          {
            label: intl.formatMessage({
              id: 'view.photo_and_video.photo_upload',
              defaultMessage: 'Photo upload',
            }),
            disabled: false,
          },
          {
            label: intl.formatMessage({
              id: 'view.photo_and_video.video_upload',
              defaultMessage: 'VIdeo upload',
            }),
            disabled: false,
          },
        ]}
      />
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={handleCloseToastMessage}
        customMessage={intl.formatMessage({
          id: 'toast.save.info',
          defaultMessage:
            'Die Änderungen werden über Nacht umgesetzt und sind am nächsten Tag erst sichtbar.',
        })}
        customIcon={InfoIcon}
      />
      {casPublicId && userAttributes?.attributes && assets && (
        <>
          <TabPanelNavigation value={currentTab} index={0}>
            <LogoForm
              casPublicId={casPublicId}
              logoAsset={assets.logo}
              refCallback={receiveRef}
              handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
              setOpenToastMessage={handleOpenToastMessage}
              partner={partnerData}
              refetchAssets={refetchAssets}
            />
          </TabPanelNavigation>
          <TabPanelNavigation value={currentTab} index={1}>
            <GalleryForm
              casPublicId={casPublicId}
              gallery={assets.gallery}
              refCallback={receiveRef}
              handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
              setOpenToastMessage={handleOpenToastMessage}
              partner={partnerData}
              refetchAssets={refetchAssets}
            />
          </TabPanelNavigation>
          <TabPanelNavigation value={currentTab} index={2}>
            <VideoForm
              casPublicId={casPublicId}
              videoAsset={assets.video}
              refCallback={receiveRef}
              handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
              setOpenToastMessage={handleOpenToastMessage}
              partner={partnerData}
              refetchAssets={refetchAssets}
            />
          </TabPanelNavigation>
        </>
      )}
      <CtaFooter
        callToActions={{
          primary: {
            title: intl.formatMessage({
              id: 'cta.save_and_publish',
              defaultMessage: 'Save & Publish',
            }),
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
        }}
      />
      {delayModal.open && (
        <DelayModal
          open
          title={intl.formatMessage({
            id: 'delayModal.title.info',
            defaultMessage: 'Verzögerte Sichtbarkeit',
          })}
          iconSrc={InfoIcon}
          onChecked={val => {
            dispatch({
              type: DelayModalTypes.SET_NOT_SHOW_PROFILE,
              payload: { notShowProfileModal: val },
            });
          }}
          content={intl.formatMessage({
            id: 'delayModal.info',
            defaultMessage:
              'Deine Änderungen im Hansefit Verbundpartner-Portal werden über Nacht umgesetzt. Somit sind die Änderungen erst am nächsten Tag in der Hansefit App oder auf der Website sichtbar.',
          })}
        />
      )}

      {showPreview && <PreviewModal onClose={handleClosePreview} />}
    </>
  );
};

export default PhotoAndVideo;
