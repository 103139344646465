import { Box, Icon, MenuItem } from '@mui/material';
import {
  GridColDef,
  gridFilteredSortedRowIdsSelector,
  GridToolbarProps,
  ToolbarPropsOverrides,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GridToolbarWrapper } from '@/App/Shared/DataGridTable/Toolbar/Toolbar.styles';
import InputDateRangePicker from '@/App/Shared/DateRangePicker/DateRangePicker';
import { Button } from '@/Components';
import BasicPopover from '@/Components/PopoverWithButton/PopoverWithButton';
import DownloadIcon from '@/Static/Icons/atoms-icon-download.svg';
import exportHelper from '@/Utils/exportHelper';

import { useTableStyles } from './Table.styles';

type DateSelectionTypeProps = { startDate?: Date; endDate?: Date };

const StatisticsTableToolbar = ({
  value,
  handleSubmit,
  fieldsToExport = [],
}: {
  handleSubmit?: (dates: DateSelectionTypeProps) => void;
  value?: DateSelectionTypeProps;
  fieldsToExport?: GridColDef[];
} & GridToolbarProps &
  ToolbarPropsOverrides) => {
  const { classes } = useTableStyles();
  const tableApiRef = useGridApiContext();

  const { formatMessage } = useIntl();
  const [dateRange, setDateRange] = useState<DateSelectionTypeProps>(value || {});
  const visibleRowIds = gridFilteredSortedRowIdsSelector(tableApiRef);

  const filteredRows = useMemo(() => {
    const rowData = tableApiRef?.current?.state?.rows?.dataRowIdToModelLookup || {};

    return visibleRowIds.map(id => rowData[id]);
  }, [tableApiRef, visibleRowIds]);

  const applyDateFilter = () => {
    if (dateRange && handleSubmit) handleSubmit(dateRange);
  };

  return (
    <GridToolbarWrapper>
      <Box width='100%' display='flex' alignItems='center'>
        <Box height={40} display='flex'>
          <InputDateRangePicker onSelect={dates => setDateRange(dates)} />
        </Box>
        <Button
          className={classes.filtersApplyBtn}
          disabled={!(dateRange?.startDate && dateRange?.endDate)}
          variant='contained'
          onClick={applyDateFilter}>
          {formatMessage({
            id: 'table.apply.bulk.action',
            defaultMessage: 'Apply',
          })}
        </Button>
        <Box mx={4} className={classes.toolbarButton}>
          <BasicPopover
            disabled={!filteredRows.length}
            buttonLabel={
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                <Icon sx={{ marginRight: 0.5, opacity: !filteredRows.length ? 0.5 : 1 }}>
                  <img src={DownloadIcon} alt='' />
                </Icon>

                {formatMessage({
                  id: 'table.toolbar.export.label',
                  defaultMessage: 'Export',
                })}
              </Box>
            }>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <MenuItem onClick={() => exportHelper(filteredRows, fieldsToExport, 'EXCEL')}>
                <FormattedMessage
                  id='table.toolbar.export.excel'
                  defaultMessage='Download Excel (xlsx)'
                />
              </MenuItem>
              <MenuItem onClick={() => exportHelper(filteredRows, fieldsToExport, 'CSV')}>
                <FormattedMessage id='table.toolbar.export.csv' defaultMessage='Download CSV' />
              </MenuItem>
              <MenuItem onClick={() => exportHelper(filteredRows, fieldsToExport, 'PDF')}>
                <FormattedMessage id='table.toolbar.export.pdf' defaultMessage='Download PDF' />
              </MenuItem>
            </Box>
          </BasicPopover>
        </Box>
      </Box>
    </GridToolbarWrapper>
  );
};

export default StatisticsTableToolbar;
