import moment from 'moment';

export const today = new Date();
export const yesterday = new Date(new Date().setDate(today.getDate() - 1));

export const firstOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
export const defaultStatisticsRange = {
  startDate: moment(firstOfCurrentMonth).toDate(),
  endDate: moment(yesterday).toDate(),
};
