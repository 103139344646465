import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { Header, TabPanelNavigation } from '@/Components';
import useCoursesOverviewBreadcrumbs from '@/Hooks/Breadcrumbs/useCoursesOverviewBreadcrumbs';
import {
  getCourseOverviewTabIdx,
  getCourseOverviewTabName,
  getCoursesOverviewTabs,
  getNewCoursePathname,
} from '@/Mappers';
import {
  useCurrentPartnerIdQuery,
  useGetPartnerCourseCategoriesQuery,
  useGetPartnerQuery,
} from '@/Queries';

import { useCurrentLanguage } from '../../Hooks/useCurrentLanguage';
import ActiveCourses from './ActiveCourses';
import InactiveCourses from './InactiveCourses';

const CoursesOverview = () => {
  const intl = useIntl();
  const { tab = '' } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();

  const tabs = getCoursesOverviewTabs();

  const { data: partnerData, isLoading: isLoadingPartnerData } = useGetPartnerQuery();

  const { data: casPublicId, isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();
  const { data: partnerCourseCategories, isLoading: isLoadingCategories } =
    useGetPartnerCourseCategoriesQuery({
      currentLanguage,
    });

  const isLoading = isLoadingCategories || isLoadingPartnerData || isLoadingCasPublicId;

  useEffect(() => {
    if (partnerData && !partnerData.showCourseManager) {
      navigate(getNewCoursePathname(currentLanguage));
    }
  }, [currentLanguage, navigate, partnerData]);

  const handleTabChange = useCallback(
    (_, newValue: number) => {
      const tabName = getCourseOverviewTabName(newValue);
      navigate(`/${currentLanguage}/kursuebersicht/${tabName}`);
    },
    [navigate, currentLanguage],
  );

  const currentTab = getCourseOverviewTabIdx(tab);

  const currentBreadcrumbs = useCoursesOverviewBreadcrumbs(currentTab);

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        handleTabChange={handleTabChange}
        currentTab={getCourseOverviewTabIdx(tab)}
        header={intl.formatMessage({
          id: 'view.courses_and_events',
          defaultMessage: 'Course Manager',
        })}
        breadcrumbs={currentBreadcrumbs}
        tabs={tabs}
      />
      {casPublicId && (
        <>
          <TabPanelNavigation value={currentTab} index={0}>
            <ActiveCourses
              loading={isLoading}
              categories={partnerCourseCategories?.data || []}
              casPublicId={casPublicId}
            />
          </TabPanelNavigation>

          <TabPanelNavigation value={currentTab} index={1}>
            <InactiveCourses
              loading={isLoading}
              casPublicId={casPublicId}
              categories={partnerCourseCategories?.data || []}
            />
          </TabPanelNavigation>
        </>
      )}
    </>
  );
};

export default CoursesOverview;
