import { Box, SelectChangeEvent } from '@mui/material';
import { GridToolbarProps, ToolbarPropsOverrides } from '@mui/x-data-grid-pro';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { GridToolbarWrapper } from '@/App/Shared/DataGridTable/Toolbar/Toolbar.styles';
import { Button } from '@/Components';
import { ReactComponent as BluePlusIcon } from '@/Static/Icons/bluePlusIcon.svg';
import { EventList } from '@/Types';

import { EDITABLE_EVENT_FIELD } from '../Modals/EditEvents';
import { AddNewContainer, Selector, SelectorItem, useButtonStyles } from './Toolbar.styles';

interface EvenListToolbarProps extends GridToolbarProps, ToolbarPropsOverrides {
  handleEditEventsStreamDataModal: (eventIds: string[]) => void;
  handleEditEventsModal: (eventIds: string[]) => void;
  handlePublishEventsModal: (eventIds: string[]) => void;
  handleCancelEventsModal: (eventIds: string[]) => void;

  handleNewEventsForm?: () => void;
  showAddNew?: boolean;
  clearSelection: () => void;
}

export const EvenListToolbar = ({
  handleEditEventsStreamDataModal,
  handleEditEventsModal,
  handlePublishEventsModal,
  handleCancelEventsModal,
  handleNewEventsForm,
  showAddNew = false,
  clearSelection,
}: EvenListToolbarProps) => {
  const { formatMessage } = useIntl();
  const [valueOption, setValueOption] = useState<EDITABLE_EVENT_FIELD | null>(null);
  const { values } = useFormikContext<EventList>();
  const eventIds = values.checkedEvents;

  const { classes } = useButtonStyles();

  const updateValueHandler = (event: SelectChangeEvent<unknown>): void => {
    setValueOption(event.target.value as EDITABLE_EVENT_FIELD);
  };

  const handleApplyAction = () => {
    switch (valueOption) {
      case EDITABLE_EVENT_FIELD.EDIT:
        handleEditEventsModal(eventIds);
        break;
      case EDITABLE_EVENT_FIELD.STREAM:
        handleEditEventsStreamDataModal(eventIds);
        break;
      case EDITABLE_EVENT_FIELD.PUBLISH:
        handlePublishEventsModal(eventIds);
        break;
      case EDITABLE_EVENT_FIELD.CANCEL:
        handleCancelEventsModal(eventIds);
        break;
    }
  };

  const handleAddNewEvent = () => {
    if (handleNewEventsForm) {
      handleNewEventsForm();
    }
  };

  return (
    <GridToolbarWrapper>
      <Selector
        value={valueOption}
        onChange={updateValueHandler}
        displayEmpty
        renderValue={
          valueOption !== null
            ? undefined
            : () => (
                <Box>
                  {formatMessage({
                    id: 'view.courses_and_events.events.multi_choice.default',
                    defaultMessage: 'Select option',
                  })}
                </Box>
              )
        }>
        <SelectorItem value={EDITABLE_EVENT_FIELD.EDIT}>
          {formatMessage({
            id: 'view.courses_and_events.events.multi_choice.cta.choice',
            defaultMessage: 'Edit event',
          })}
        </SelectorItem>
        <SelectorItem value={EDITABLE_EVENT_FIELD.STREAM}>
          {formatMessage({
            id: 'view.courses_and_events.events.multi_choice.edit.streaming',
            defaultMessage: 'Edit streaming data',
          })}
        </SelectorItem>
        <SelectorItem value={EDITABLE_EVENT_FIELD.PUBLISH}>
          {formatMessage({
            id: 'view.courses_and_events.events.multi_choice.edit.publish_event',
            defaultMessage: 'Publish date',
          })}
        </SelectorItem>
        <SelectorItem value={EDITABLE_EVENT_FIELD.CANCEL}>
          {formatMessage({
            id: 'view.courses_and_events.events.multi_choice.edit.cancel_event',
            defaultMessage: 'Cancel appointment',
          })}
        </SelectorItem>
      </Selector>
      <Button
        className={classes.filtersApplyBtn}
        disabled={!eventIds.length || !valueOption}
        variant='contained'
        onClick={() => handleApplyAction()}>
        {formatMessage({
          id: 'table.apply.bulk.action',
          defaultMessage: 'Apply',
        })}
      </Button>

      {/* <FilterButton /> */}

      {showAddNew && (
        <AddNewContainer>
          <Button onClick={handleAddNewEvent} icon={<BluePlusIcon />}>
            {formatMessage({
              id: 'table.toolbar.course.add_new_event',
              defaultMessage: 'add new event',
            })}
          </Button>
        </AddNewContainer>
      )}
    </GridToolbarWrapper>
  );
};
