import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CourseForm, EventForm, EventsForm, Header, TabPanelNavigation } from '@/Components';
import { CourseManagerTypes, useAppContext } from '@/Context';
import useStaticCourseOverviewBreadcrumbs from '@/Hooks/Breadcrumbs/useStaticCourseOverviewBreadcrumbs';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import {
  BtnLabelsTypes,
  ErrorMap,
  getButtonLabel,
  getCourseAndEventsTabNumber,
  getCourseAndEventTabs,
  getCreateEventPathname,
  getDashboardPathname,
  getEditEventPathname,
  getEventsPathname,
  getInitialTabChangeCourseAndEventsPathname,
  getNewCoursePathname,
  isEditCourseView,
  isEventsView,
  isNewCourseView,
  isNewEventView,
  isPastEventListView,
} from '@/Mappers';
import {
  useCurrentPartnerIdQuery,
  useGetNumberOfPublishedEventsInFutureQuery,
  useGetNumberOfPublishEventsQuery,
  useGetPartnerCourseCategoriesQuery,
  useGetPartnerCourseQuery,
  useGetPartnerQuery,
} from '@/Queries';
import { COURSE_ACTIONS, EVENT_ACTIONS } from '@/Services';
import ErrorIcon from '@/Static/Icons/atoms-symbols-alert-error.svg';
import InfoCourseIcon from '@/Static/Icons/course_delay_icon.svg';
import { Course, Event } from '@/Types';
import {
  CrazyUseRefCallbackStuffTypes,
  isCourseFormRef,
  isEventFormRef,
} from '@/Utils/formRefTypeguards';

import { CtaFooter } from '../../App/Shared/Footer/CtaFooter';
import { FormSnackbar } from '../../App/Shared/Notification/Components/FormSnackbar';
import { useConfirmDialog } from '../../Hooks/useConfirmDialog';
import { useCurrentLanguage } from '../../Hooks/useCurrentLanguage';
import { EnableCourseManagerDialog } from './Components';
import { PastEventList } from './Components/PastEvents';

const CoursesAndEvents = () => {
  const intl = useMemoedIntl();
  const {
    courseId = '',
    action = '',
    eventId = '',
    ...additionalParams
  } = useParams<{
    courseId: string;
    action: string;
    eventId: string;
  }>();

  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: {
      courseManager: { course, updatedEvents },
    },
    dispatch,
  } = useAppContext();
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const [newCourseFormRef, setNewCourseFormRef] = useState<FormikProps<Course>>();
  const [addMeetingsFormRef, setAddMeetingsFormRef] = useState<FormikProps<Event>>();
  const [tabs, setTabs] = useState<{ label: string; disabled: boolean }[]>([]);
  const [asyncEventFormErrorMessage, setAsyncEventFormErrorMessage] = useState<string>();
  const currentLanguage = useCurrentLanguage();
  const intCourseId = useMemo(() => parseInt(courseId, 10), [courseId]);
  const intEventId = useMemo(() => parseInt(eventId, 10), [eventId]);

  const handleConfirmDialog = useConfirmDialog();
  const [primaryCtaTitle, setPrimaryCtaTitle] = useState('');
  const [primaryCtaDisabled, setPrimaryCtaDisabled] = useState(true);
  const [secondaryCtaTitle, setSecondaryCtaTitle] = useState('');
  const [secondaryCtaDisabled, setSecondaryCtaDisabled] = useState(false);

  const [defaultToast, setDefaultToast] = useState(false);
  const [customToastMessage, setCustomToastMessage] = useState('');
  const [isErrorToastIcon, setIsErrorToastIcon] = useState(false);

  const { data: partnerData } = useGetPartnerQuery();
  const { data: casPublicId } = useCurrentPartnerIdQuery();

  const { data: numberOfFuturePublishedEvents = 0 } = useGetNumberOfPublishedEventsInFutureQuery({
    courseId,
  });
  const { data: numberOfPublishedEvents = 0 } = useGetNumberOfPublishEventsQuery({
    courseId,
  });

  const { data: partnerCourseCategories } = useGetPartnerCourseCategoriesQuery({
    currentLanguage,
  });

  const { data: courseData, isSuccess } = useGetPartnerCourseQuery({
    casPublicId,
    courseId,
  });

  useEffect(() => {
    if (isSuccess && courseData) {
      dispatch({ type: CourseManagerTypes.SET_COURSE, payload: { course: courseData.data } });
    }
  }, [courseData, dispatch, isSuccess]);

  const handleToastMessage = useCallback((value: boolean) => {
    setOpenToastMessage(value);
  }, []);

  const handleTabChange = useCallback(
    (_, newValue: number) => {
      const pathname = getInitialTabChangeCourseAndEventsPathname(
        { ...additionalParams, courseId: intCourseId, eventId: intEventId },
        newValue,
        currentLanguage,
      );
      navigate(pathname);
    },
    [additionalParams, intCourseId, intEventId, currentLanguage, navigate],
  );

  const receiveRef = useCallback((ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => {
    if (tabNumber === 0 && isCourseFormRef(ref) && ref.current) {
      setNewCourseFormRef(ref.current);
    }

    if (tabNumber === 1 && isEventFormRef(ref) && ref.current) {
      setAddMeetingsFormRef(ref.current);
    }
  }, []);

  const handlePrimaryCtaDisabled = useCallback((value: boolean) => {
    setPrimaryCtaDisabled(value);
  }, []);

  const handleSecondaryCtaDisabled = useCallback((value: boolean) => {
    setSecondaryCtaDisabled(value);
  }, []);

  const currentTab = useMemo(
    () =>
      getCourseAndEventsTabNumber(
        { ...additionalParams, courseId: intCourseId, eventId: intEventId },
        window.location.pathname,
        currentLanguage,
      ),
    [additionalParams, intCourseId, intEventId, currentLanguage],
  );

  const handlePrimaryCta = useCallback(async () => {
    setDefaultToast(false);
    if (
      (isEditCourseView(intCourseId, action) || isNewCourseView(location.pathname)) &&
      newCourseFormRef
    ) {
      newCourseFormRef.setStatus({ action: COURSE_ACTIONS.SAVE });
      await newCourseFormRef.submitForm();
    }

    if (currentTab === 1 && addMeetingsFormRef) {
      addMeetingsFormRef.setStatus({ action: EVENT_ACTIONS.SAVE });
      await addMeetingsFormRef.submitForm();
    }

    if (currentTab === 2) {
      navigate(-1);
    }
  }, [
    intCourseId,
    action,
    location.pathname,
    newCourseFormRef,
    currentTab,
    addMeetingsFormRef,
    navigate,
  ]);

  const handleSecondaryCta = useCallback(async () => {
    setDefaultToast(true);

    if (isEditCourseView(intCourseId, action) && !isEmpty(newCourseFormRef)) {
      if (secondaryCtaTitle === getButtonLabel(intl, BtnLabelsTypes.DELETE)) {
        newCourseFormRef.setStatus({ action: COURSE_ACTIONS.DELETE });
        return await newCourseFormRef.submitForm();
      }

      if (secondaryCtaTitle === getButtonLabel(intl, BtnLabelsTypes.ARCHIVE)) {
        newCourseFormRef.setStatus({ action: COURSE_ACTIONS.ARCHIVE });
        return await newCourseFormRef.submitForm();
      }
    }

    if (currentTab === 1 && addMeetingsFormRef) {
      addMeetingsFormRef.setStatus({ action: EVENT_ACTIONS.DRAFT });
      return await addMeetingsFormRef.submitForm();
    }
  }, [
    intCourseId,
    action,
    newCourseFormRef,
    currentTab,
    addMeetingsFormRef,
    secondaryCtaTitle,
    intl,
  ]);

  const handleEventFormAsyncErrorMessage = useCallback(
    (message: string) => {
      setAsyncEventFormErrorMessage(message);

      if (message) {
        new ErrorMap().scrollToTop();
      }
    },
    [setAsyncEventFormErrorMessage],
  );

  useEffect(() => {
    // not on create course page
    if (intCourseId) {
      if (!numberOfPublishedEvents && !numberOfFuturePublishedEvents) {
        setSecondaryCtaTitle(getButtonLabel(intl, BtnLabelsTypes.DELETE));
        setSecondaryCtaDisabled(false);
      } else if (numberOfPublishedEvents && !numberOfFuturePublishedEvents) {
        setSecondaryCtaTitle(getButtonLabel(intl, BtnLabelsTypes.ARCHIVE));
        setSecondaryCtaDisabled(false);
      } else {
        setSecondaryCtaTitle(getButtonLabel(intl, BtnLabelsTypes.ARCHIVE));
        setSecondaryCtaDisabled(true);
      }

      if (course && !course.published) {
        setPrimaryCtaTitle(getButtonLabel(intl, BtnLabelsTypes.SAVE_EVENT));
      } else {
        setPrimaryCtaTitle(getButtonLabel(intl, BtnLabelsTypes.PUBLISH));
      }

      setPrimaryCtaDisabled(true);
    }

    if (intCourseId && isPastEventListView(intCourseId, action)) {
      setPrimaryCtaTitle(getButtonLabel(intl, BtnLabelsTypes.BACK));
      setPrimaryCtaDisabled(false);
      setSecondaryCtaTitle('');
    }

    if (location && isNewCourseView(location.pathname)) {
      setPrimaryCtaDisabled(true);
      setPrimaryCtaTitle(getButtonLabel(intl, BtnLabelsTypes.SAVE_EVENT));
      setSecondaryCtaDisabled(false);
    }

    if (intCourseId && isEventsView(intCourseId, action)) {
      setPrimaryCtaDisabled(true);
      setSecondaryCtaDisabled(true);
      setSecondaryCtaTitle('');
    }

    if (intCourseId && isNewEventView(intCourseId, action)) {
      setPrimaryCtaTitle(getButtonLabel(intl, BtnLabelsTypes.PUBLISH));
      setPrimaryCtaDisabled(true);
      setSecondaryCtaDisabled(true);
      setSecondaryCtaTitle('');
    }

    setTabs(getCourseAndEventTabs({ currentTab, courseId }));
  }, [
    action,
    intl,
    location,
    numberOfPublishedEvents,
    numberOfFuturePublishedEvents,
    course,
    currentTab,
    intCourseId,
    courseId,
    location.pathname,
  ]);

  useEffect(() => {
    if (isEventsView(intCourseId, action)) {
      if (!updatedEvents) {
        setPrimaryCtaDisabled(true);
        handleConfirmDialog(false);
      } else {
        setPrimaryCtaDisabled(false);
        handleConfirmDialog(true);
      }
    }
  }, [action, handleConfirmDialog, intCourseId, updatedEvents]);

  const handleShowEventForm = useCallback(
    (value: boolean) => {
      if (!value) {
        setSecondaryCtaTitle('');
      }
    },
    [setSecondaryCtaTitle],
  );

  useEffect(
    () => () => {
      dispatch({ type: CourseManagerTypes.SET_COURSE, payload: { course: null } });
    },
    [dispatch],
  );

  useEffect(() => {
    if (partnerData && !partnerData.showCourseManager) {
      navigate(getNewCoursePathname(currentLanguage));
    }
  }, [currentLanguage, navigate, partnerData]);

  const staticCourseOverviewBreadcrumbs = useStaticCourseOverviewBreadcrumbs();

  const currentBreadcrumbs = useMemo(() => {
    if (isNewCourseView(location.pathname)) {
      return [
        ...staticCourseOverviewBreadcrumbs,
        {
          title: intl.formatMessage({
            id: 'breadcrumbs.new_course',
            defaultMessage: 'create new course',
          }),
          href: getNewCoursePathname(currentLanguage),
        },
      ];
    }

    if (course?.courseId && isEditCourseView(course.courseId, action)) {
      return [
        ...staticCourseOverviewBreadcrumbs,
        {
          title: intl.formatMessage({
            id: 'breadcrumbs.edit_course',
            defaultMessage: 'Edit course',
          }),
          href: getEditEventPathname(currentLanguage, intCourseId),
        },
      ];
    }

    if (course?.courseId && isEventsView(course.courseId, action)) {
      return [
        ...staticCourseOverviewBreadcrumbs,
        {
          title: intl.formatMessage({
            id: 'breadcrumbs.events',
            defaultMessage: 'Dates',
          }),
          href: getEventsPathname(currentLanguage, intCourseId),
        },
      ];
    }

    if (course?.courseId && isNewEventView(course.courseId, action)) {
      return [
        ...staticCourseOverviewBreadcrumbs,
        ...[
          {
            title: intl.formatMessage({
              id: 'breadcrumbs.events',
              defaultMessage: 'Dates',
            }),
            href: getEventsPathname(currentLanguage, intCourseId),
          },
          {
            title: intl.formatMessage({
              id: 'breadcrumbs.add_event',
              defaultMessage: 'Add appointment',
            }),
            href: getCreateEventPathname(currentLanguage, intCourseId),
          },
        ],
      ];
    }

    if (course?.courseId && isPastEventListView(course.courseId, action)) {
      return [
        ...staticCourseOverviewBreadcrumbs,
        {
          title: intl.formatMessage({
            id: 'breadcrumbs.past_events',
            defaultMessage: 'Past dates',
          }),
          href: getEventsPathname(currentLanguage, intCourseId),
        },
      ];
    }

    return [];
  }, [
    location.pathname,
    course,
    action,
    staticCourseOverviewBreadcrumbs,
    intl,
    currentLanguage,
    intCourseId,
  ]);

  useEffect(() => {
    if (action === 'termin-hinzufuegen' && partnerData && partnerData.readonly) {
      const dashboard = getDashboardPathname(currentLanguage);
      navigate(dashboard);
    }
  }, [currentLanguage, navigate, partnerData, action]);

  return (
    <>
      <Header
        showLanguageSwitcher={currentTab === 0}
        callToActions={{
          primary: {
            title: primaryCtaTitle,
            callback: handlePrimaryCta,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
          secondary: {
            title: secondaryCtaTitle,
            callback: handleSecondaryCta,
            disabled: secondaryCtaDisabled,
            variant: 'outlined',
          },
        }}
        handleTabChange={handleTabChange}
        currentTab={currentTab}
        header={intl.formatMessage({
          id: 'view.courses_and_events',
          defaultMessage: 'Course Manager',
        })}
        breadcrumbs={currentBreadcrumbs}
        tabs={tabs}
      />
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={() => handleToastMessage(false)}
        customMessage={
          defaultToast
            ? customToastMessage
            : intl.formatMessage({
                id: 'toast.save.course',
                defaultMessage:
                  'Deine Änderungen werden innerhalb der nächsten 10 min. in der App und im Web sichtbar.',
              })
        }
        customIcon={isErrorToastIcon ? ErrorIcon : InfoCourseIcon}
      />
      {casPublicId && partnerData && (isNewCourseView(location.pathname) || course) && (
        <>
          <TabPanelNavigation value={currentTab} index={0}>
            <CourseForm
              key={location.pathname + '_1'}
              casPublicId={casPublicId}
              categories={partnerCourseCategories?.data || []}
              refCallback={receiveRef}
              handleToastMessage={handleToastMessage}
              handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
            />
          </TabPanelNavigation>
          <TabPanelNavigation value={currentTab} index={1}>
            {action !== 'termin-hinzufuegen' ? (
              <EventsForm
                course={course}
                casPublicId={casPublicId}
                refCallback={receiveRef}
                handleToastMessage={handleToastMessage}
                hideCourseName
                showAddNew
              />
            ) : (
              <EventForm
                handleShowEventForm={handleShowEventForm}
                courseId={intCourseId}
                casPublicId={casPublicId}
                refCallback={receiveRef}
                handleToastMessage={handleToastMessage}
                handleAsyncErrorMessage={handleEventFormAsyncErrorMessage}
                asyncEventFormErrorMessage={asyncEventFormErrorMessage}
                handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                handleSecondaryCtaDisabled={handleSecondaryCtaDisabled}
              />
            )}
          </TabPanelNavigation>
          <TabPanelNavigation value={currentTab} index={2}>
            <PastEventList courseId={intCourseId} casPublicId={casPublicId} />
          </TabPanelNavigation>
          {!partnerData.showCourseManager && (
            <EnableCourseManagerDialog
              publicId={casPublicId}
              setCustomToastMessage={setCustomToastMessage}
              setDefaultToast={setDefaultToast}
              setIsErrorToastIcon={setIsErrorToastIcon}
              handleToastMessage={handleToastMessage}
            />
          )}
        </>
      )}
      <CtaFooter
        callToActions={{
          primary: {
            title: primaryCtaTitle,
            callback: handlePrimaryCta,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
          secondary: {
            title: secondaryCtaTitle,
            callback: handleSecondaryCta,
            disabled: secondaryCtaDisabled,
            variant: 'outlined',
          },
        }}
      />
    </>
  );
};

export default CoursesAndEvents;
