import { FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DescriptionForm,
  Header,
  OpeningHoursForm,
  PreviewModal,
  SpinnerLoader,
  TabPanelNavigation,
} from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import useDescriptionAndOpeningHoursBreadcrumbs from '@/Hooks/Breadcrumbs/useDescriptionAndOpeningHoursBreadcrumbs';
import { getDescAndOpenHoursTabIdx, getDescAndOpenHoursTabName } from '@/Mappers';
import { useCurrentPartnerIdQuery, useCurrentUserInfoQuery, useGetPartnerQuery } from '@/Queries';
import InfoIcon from '@/Static/Icons/info_delay_icon.svg';
import { DescriptionPartial, OpeningHoursPartial } from '@/Types';
import {
  CrazyUseRefCallbackStuffTypes,
  isDescriptionFormRef,
  isOpeningHoursFormRef,
} from '@/Utils/formRefTypeguards';
import handleCatchError from '@/Utils/handleCatchError';

import { CtaFooter } from '../../App/Shared/Footer/CtaFooter';
import { DelayModal } from '../../App/Shared/Modal/DelayModal';
import { FormSnackbar } from '../../App/Shared/Notification/Components/FormSnackbar';
import { useCurrentLanguage } from '../../Hooks/useCurrentLanguage';

const DescriptionAndOpeningHours = () => {
  const intl = useIntl();
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();

  const { tab = '' } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const [currentTab, setCurrentTab] = useState(getDescAndOpenHoursTabIdx(tab));
  const [descriptionFormRef, setDescriptionFormRef] = useState<FormikProps<DescriptionPartial>>();
  const [openingHoursFormRef, setOpeningHoursFormRef] =
    useState<FormikProps<OpeningHoursPartial>>();
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const [primaryCtaDisabled, setPrimaryCtaDisabled] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  const handlePrimaryCtaDisabled = useCallback((value: boolean) => {
    setPrimaryCtaDisabled(value);
  }, []);

  const handleCloseToastMessage = useCallback(() => {
    setOpenToastMessage(false);
  }, []);

  const handleOpenToastMessage = useCallback(
    (openToastMessage: boolean) => {
      if (!delayModal.notShowProfileModal) {
        return;
      }
      setOpenToastMessage(openToastMessage);
    },
    [delayModal.notShowProfileModal],
  );

  const handleTabChange = useCallback(
    (_, newValue: number) => {
      const tabName = getDescAndOpenHoursTabName(newValue);
      navigate(`/${currentLanguage}/beschreibung-und-oeffnungszeiten/${tabName}`);
    },
    [currentLanguage, navigate],
  );

  useEffect(() => {
    const newTab = getDescAndOpenHoursTabIdx(tab);
    setCurrentTab(newTab);
    setPrimaryCtaDisabled(true);
  }, [tab, setCurrentTab, setPrimaryCtaDisabled]);

  const receiveRef = useCallback(
    (ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => {
      if (tabNumber === 0 && isDescriptionFormRef(ref) && ref.current) {
        setDescriptionFormRef(ref.current);
      }

      if (tabNumber === 1 && isOpeningHoursFormRef(ref) && ref.current) {
        setOpeningHoursFormRef(ref.current);
      }
    },
    [setDescriptionFormRef, setOpeningHoursFormRef],
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (currentTab === 0 && descriptionFormRef) {
        await descriptionFormRef.submitForm();
      }

      if (currentTab === 1 && openingHoursFormRef) {
        await openingHoursFormRef.submitForm();
      }
    } catch (error: unknown) {
      handleCatchError(error);
    }
  }, [currentTab, descriptionFormRef, openingHoursFormRef]);

  const handleShowPreview = useCallback(() => {
    setShowPreview(true);
  }, []);

  const handleClosePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const { data: partnerData, isLoading: isLoadingPartnerData } = useGetPartnerQuery();
  const { data: userAttributes, isLoading: isLoadingUserAttributes } = useCurrentUserInfoQuery();
  const { data: casPublicId, isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();

  const currentBreadcrumbs = useDescriptionAndOpeningHoursBreadcrumbs(currentTab);

  const isLoading = isLoadingUserAttributes || isLoadingCasPublicId || isLoadingPartnerData;

  return (
    <>
      <Header
        showLanguageSwitcher
        callToActions={{
          primary: {
            title: intl.formatMessage({
              id: 'cta.save_and_publish',
              defaultMessage: 'Save & Publish',
            }),
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
          secondary: {
            title: intl.formatMessage({
              id: 'cta.preview_partner',
              defaultMessage: 'Preview Studio Profile',
            }),
            callback: handleShowPreview,
            disabled: false,
            variant: 'outlined',
          },
        }}
        handleTabChange={handleTabChange}
        currentTab={currentTab}
        header={intl.formatMessage({
          id: 'view.description_and_opening_hours',
          defaultMessage: 'Description & opening hours',
        })}
        breadcrumbs={currentBreadcrumbs}
        tabs={[
          {
            label: 'view.description_and_opening_hours.description',
            disabled: false,
          },
          {
            label: 'view.description_and_opening_hours.opening_hours',
            disabled: false,
          },
        ]}
      />
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={handleCloseToastMessage}
        customMessage={intl.formatMessage({
          id: 'toast.save.info',
          defaultMessage:
            'Die Änderungen werden über Nacht umgesetzt und sind am nächsten Tag erst sichtbar.',
        })}
        customIcon={InfoIcon}
      />
      {isLoading && <SpinnerLoader isFullScreen />}

      {casPublicId && userAttributes && partnerData && (
        <>
          <TabPanelNavigation value={currentTab} index={0}>
            <DescriptionForm
              casPublicId={casPublicId}
              partner={partnerData}
              refCallback={receiveRef}
              handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
              setOpenToastMessage={handleOpenToastMessage}
            />
          </TabPanelNavigation>

          <TabPanelNavigation value={currentTab} index={1}>
            <OpeningHoursForm
              casPublicId={casPublicId}
              partner={partnerData}
              refCallback={receiveRef}
              handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
              setOpenToastMessage={handleOpenToastMessage}
            />
          </TabPanelNavigation>
        </>
      )}
      <CtaFooter
        callToActions={{
          primary: {
            title: intl.formatMessage({
              id: 'cta.save_and_publish',
              defaultMessage: 'Save & Publish',
            }),
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
        }}
      />
      {delayModal.open && (
        <DelayModal
          open
          title={intl.formatMessage({
            id: 'delayModal.title.info',
            defaultMessage: 'Verzögerte Sichtbarkeit',
          })}
          iconSrc={InfoIcon}
          onChecked={val => {
            dispatch({
              type: DelayModalTypes.SET_NOT_SHOW_PROFILE,
              payload: { notShowProfileModal: val },
            });
          }}
          content={intl.formatMessage({
            id: 'delayModal.info',
            defaultMessage:
              'Deine Änderungen im Hansefit Verbundpartner-Portal werden über Nacht umgesetzt. Somit sind die Änderungen erst am nächsten Tag in der Hansefit App oder auf der Website sichtbar.',
          })}
        />
      )}

      {showPreview && <PreviewModal onClose={handleClosePreview} />}
    </>
  );
};

export default DescriptionAndOpeningHours;
