import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const useLoginStyles = makeStyles()(() => ({
  mainContent: {
    width: 620,
    height: 'fit-content',
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '20px 60px',
    paddingBottom: 0,
    position: 'relative',
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
    justifyContent: 'center',
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 25,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: FigmaDesignTokens.White,
  },
  mainHeader: {
    marginTop: 10,
    marginBottom: 48,
    width: 391,
  },
  headerForm: {
    color: FigmaDesignTokens.Blue[950],
    fontSize: 25,
    marginTop: 10,
  },
  subHeader: {
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[950],
    textAlign: 'center',
  },
}));

export const useLoginPhotosStyles = makeStyles()(theme => ({
  img: {
    position: 'absolute',
    width: 408,
    top: -10,
    left: 504,
  },
}));
