import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { de, enGB } from 'date-fns/locale';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { useHeaderTableStyles } from '@/App/Shared/DataGridTable/Table/DataGridTable';
import { BodyTextSmall, Header, SubHeadlineDisplay } from '@/Components';
import useStaticStatisticBreadcrumbs from '@/Hooks/Breadcrumbs/useStaticStatisticBreadcrumbs';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { getStaticPathname } from '@/Mappers';
import { useCheckInStatsQuery, useGetPartnerQuery } from '@/Queries';
import { DateSelectionType, GetMemberStatsResponseType, StatisticsType } from '@/Types';
import { defaultStatisticsRange, formatDate } from '@/Utils';
import CheckInStatsTable from '@/Views/CheckInAll/Components/CheckInStatsTable';

const CheckInByUser = () => {
  const { classes } = useHeaderTableStyles();
  const currentLanguage = useCurrentLanguage();
  const { formatMessage } = useIntl();
  const { data: partnerData } = useGetPartnerQuery();

  const [dates, setDates] = useState<DateSelectionType>(defaultStatisticsRange);

  const {
    data,
    isLoading,
    // isError: isErrorData,
  } = useCheckInStatsQuery<GetMemberStatsResponseType>({
    partnerPublicId: partnerData?.publicId,
    statsType: StatisticsType.Member,
    endDate: dates.endDate && formatDate(dates?.endDate),
    startDate: dates.startDate && formatDate(dates?.startDate),
  });

  const staticStatisticBreadcrumbs = useStaticStatisticBreadcrumbs();
  const currentBreadcrumbs = useMemo(
    () => [
      ...staticStatisticBreadcrumbs,
      {
        title: formatMessage({
          id: 'statistic.title.check_in_by_member',
          defaultMessage: 'Check-Ins pro Mitglieder',
        }),
        href: getStaticPathname(currentLanguage, 'check-ins-mitglieder'),
      },
    ],
    [currentLanguage, staticStatisticBreadcrumbs, formatMessage],
  );

  const totalNumberOfCheckins = useMemo(() => {
    if (!data?.data || !Object.keys(data?.data || {}).length) return 0;

    return Object.values(data.data).reduce((acc, cur) => acc + cur.checkins, 0);
  }, [data?.data]);

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        header={formatMessage({
          id: 'statistic.title.check_in_by_member',
          defaultMessage: 'Check-Ins pro Mitglieder',
        })}
        breadcrumbs={currentBreadcrumbs}
      />

      <Box mt={4}>
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Box
            className={classes.checkIn}
            mb={2}
            style={{
              width: 'fit-content',
            }}>
            <BodyTextSmall
              fontWeight={500}
              textContent={`${formatMessage({
                id: 'view.statistic.company.number_check_in',
                defaultMessage: 'Number of check-ins',
              })}: `}
            />
            <SubHeadlineDisplay textContent={totalNumberOfCheckins.toString()} />
          </Box>
        </div>
        <ContentContainer padding={0}>
          <Grid item xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={currentLanguage === 'en' ? enGB : de}>
              <CheckInStatsTable<GetMemberStatsResponseType>
                data={data?.data || []}
                setDateRange={setDates}
                dateRange={dates}
                isLoading={isLoading}
                lastColumn='checkins'
              />
            </LocalizationProvider>
          </Grid>
        </ContentContainer>
      </Box>
    </>
  );
};

export default CheckInByUser;
