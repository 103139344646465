import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import { useCallback, useState } from 'react';

import { Button, Dropzone, UploadIndicator } from '@/Components';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { getDefaultFilename, getDefaultGalleryAsset } from '@/Mappers';
import { AssetService } from '@/Services';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import ChangeIcon from '@/Static/Icons/atoms-symbols-icons-media-change.svg';
import { GalleryAsset, Partner, Upload } from '@/Types';

import { GalleryAssetPreview } from '../Forms/Assets/GalleryAssetPreview';
import { useAlterGalleryImageStyles } from './Styles/AlterGalleryImage.styles';

interface AlterGalleryImageModalProps {
  resourceData: GalleryAsset;
  assetService: AssetService;
  casPublicId: Partner['casPublicId'];
  handleNewFileKey: (value: string) => void;
  newFileKey: string;
  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;
  idx: number;
  handleNewFileAssetId: (value: number | undefined) => void;
  handleNewOriginalFilename: (value: string | null) => void;
  newFileAssetId: number | undefined;
  newOriginalFilename: string | null;
}

export const AlterGalleryImageModal = (props: AlterGalleryImageModalProps) => {
  const {
    assetService,
    casPublicId,
    resourceData,
    handleNewFileKey,
    newFileKey,
    idx,
    handleReplaceImage,
    handleNewFileAssetId,
    newFileAssetId,
    newOriginalFilename,
    handleNewOriginalFilename,
  } = props;

  const [open, setOpen] = useState(false);
  const { classes } = useAlterGalleryImageStyles();
  const { formatMessage } = useMemoedIntl();
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const [uploads, setUploads] = useState<Upload[]>([]);

  const handleCancel = useCallback((managedUpload: ManagedUpload) => {
    managedUpload.abort.bind(managedUpload);
    managedUpload.abort();
    setUploads([]);
  }, []);

  const handleDrop = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const file = files[0];

        const filename = getDefaultFilename(file.name);
        const managedUpload = await assetService.S3Upload(
          casPublicId,
          filename,
          'Gallery',
          file,
          (progressEvent: ManagedUpload.Progress) => {
            const upload: Upload = {
              file: files[0],
              progress: progressEvent,
              managedUpload,
            };

            setUploads([upload]);
          },
          err => {
            if (err) {
              setUploads([
                {
                  file,
                  errorMessage: formatMessage({
                    id: 'upload.failed',
                    defaultMessage: 'Upload failed',
                  }),
                },
              ]);
              return;
            }

            assetService
              .assignGalleryImage(casPublicId, {
                filename,
                originalFilename: file.name,
              })
              .then(response => {
                handleNewFileAssetId(response.data.assetId);
                handleNewFileKey(filename);
                handleNewOriginalFilename(file.name);

                setUploads([]);
              });
          },
        );
      }
    },
    [
      assetService,
      casPublicId,
      formatMessage,
      handleNewFileAssetId,
      handleNewFileKey,
      handleNewOriginalFilename,
    ],
  );

  const handleApplyChanges = useCallback(() => {
    newFileAssetId && handleReplaceImage(idx, newFileKey, newFileAssetId, newOriginalFilename);
    handleNewFileKey('');
    handleNewFileAssetId(undefined);
    handleNewOriginalFilename(null);
    handleClose();
  }, [
    newFileAssetId,
    handleReplaceImage,
    idx,
    newFileKey,
    newOriginalFilename,
    handleNewFileKey,
    handleNewFileAssetId,
    handleNewOriginalFilename,
    handleClose,
  ]);

  return (
    <>
      <Button className={classes.btnModal} onClick={handleOpen} variant='text'>
        <img alt='' src={ChangeIcon} />
        <Typography variant='body2' className={classes.btnText}>
          {formatMessage({
            id: 'alter.gallery.image.cta.replace_image',
            defaultMessage: 'Replace image',
          })}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          style: {
            padding: '50px 30px 30px 30px',
            maxWidth: 'unset',
            width: 696,
          },
        }}>
        <DialogTitle classes={{ root: 'dialogImgTitle' }} className={classes.dialogImgTitle}>
          <Box className={classes.headingWrapper}>
            {formatMessage({
              id: 'alter.gallery.image.cta.replace_image',
              defaultMessage: 'Replace image',
            })}
            <IconButton aria-label='close' onClick={handleClose}>
              <img alt='' src={CloseIcon} />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <GalleryAssetPreview
                assetService={assetService}
                casPublicId={casPublicId}
                resourceData={resourceData}
                isBinIcon={false}
              />
            </Grid>
            <Grid item xs={7}>
              {newFileKey && newFileAssetId && (
                <GalleryAssetPreview
                  assetService={assetService}
                  casPublicId={casPublicId}
                  resourceData={getDefaultGalleryAsset(
                    newFileAssetId,
                    newFileKey,
                    newOriginalFilename,
                  )}
                  handleRemoveNewImage={() => handleNewFileKey('')}
                  isBinIcon
                />
              )}
              {uploads.length > 0 && (
                <UploadIndicator uploads={uploads} width={195} handleCancel={handleCancel} />
              )}
              {!newFileKey && !uploads.length && (
                <Box className={classes.dropzoneWrapper}>
                  <Dropzone
                    disabled={false}
                    maxFileSize={15000000}
                    supportedFileNames='JPEG, PNG, HEIC, TIFF'
                    onDrop={handleDrop}
                    accept='image/jpeg, image/png, image/heic, image/tiff'
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleApplyChanges} disabled={!newFileAssetId}>
            {formatMessage({
              id: 'alter.gallery.image.cta.apply',
              defaultMessage: 'Apply changes',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
