import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, ClickAwayListener, Divider, Fade } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import { uiActions } from '@/Store';
import { useGetPartnerNamesQuery } from '@/Queries';
import { ProfilePartnerItem } from './ProfilePartnerItem';
import {
  SubHeadlineDisplay,
  DisplayTextAccent,
  OverlineText,
  LineDivider,
  UserProfileIcon,
  Button,
} from '@/Components';

import { UserAttr, Partner, LanguageType } from '@/Types';
import LocationIcon from '@/Static/Icons/atoms-symbols-icons-location.svg';
import { ReactComponent as LogoutIcon } from '@/Static/Icons/outsideIcon.svg';
import Theme from '@/App/Theme/Theme';
import { useHeaderStyles } from './Styles/Header.styles';

type ProfileDropDownProps = {
  userAttr?: UserAttr;
  partner?: Partner;
};

export const ProfileDropdown = ({ userAttr, partner }: ProfileDropDownProps) => {
  const { classes } = useHeaderStyles()();
  const params = useParams<{ language: LanguageType }>();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpenProfileDropdown, setIsOpenProfileDropdown] = useState(false);

  const { data: partnerNamesList = [], refetch: partnerNamesListRefetch } =
    useGetPartnerNamesQuery();

  const handleChange = useCallback(() => {
    setIsOpenProfileDropdown(prev => !prev);
  }, []);

  const handleClickAway = useCallback(() => {
    setIsOpenProfileDropdown(false);
  }, []);

  const handleOpenVerifyPartnerModal = useCallback(() => {
    dispatch(
      uiActions.showModal('ADD_ANOTHER_STUDIO', {
        partnerNamesListRefetch,
        partner,
      }),
    );

    setIsOpenProfileDropdown(false);
  }, [dispatch, partner, partnerNamesListRefetch]);

  const signOut = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box style={{ position: 'relative' }}>
          <Button className={classes.btnPopover} onClick={handleChange} variant='outlined'>
            <UserProfileIcon userAttr={userAttr} size='large' />
            <SubHeadlineDisplay textContent={partner?.studioName || 'N/A'} />
            {isOpenProfileDropdown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>
          <Fade in={isOpenProfileDropdown}>
            <Box className={classes.menuWrapper}>
              <Box className={classes.popover}>
                <Box className={classes.headerWrapper}>
                  {partner?.studioName ? (
                    <OverlineText
                      textContent={`${partner.studioName.toUpperCase()}: ${partner.casId}`}
                      customColor={Theme.palette.background.paper}
                      fontWeight={600}
                    />
                  ) : null}
                </Box>

                <Box className={classes.navigationList}>
                  <Box className={classes.navigationHeaderWrapper}>
                    <img src={LocationIcon} className={classes.navigationHeaderImg} alt='' />
                    <DisplayTextAccent
                      textContent={formatMessage({
                        id: 'profile.dropdown.studios_and_locations',
                        defaultMessage: 'Studios & Locations',
                      })}
                    />
                  </Box>

                  <Button
                    className={classes.btnNewStudio}
                    onClick={handleOpenVerifyPartnerModal}
                    variant='outlined'
                    textTransform>
                    {formatMessage({
                      id: 'profile.dropdown.add_studio',
                      defaultMessage: '+ add another studio',
                    })}
                  </Button>

                  <Box className={classes.partnerListContainer}>
                    {partnerNamesList.map((partnerListItem, idx) => (
                      <ProfilePartnerItem
                        translate={false}
                        key={idx}
                        title={partnerListItem.studioName}
                        publicId={partnerListItem.publicId}
                        href={`/${params.language}/partner-wechseln?publicId=${partnerListItem.publicId}&name=${partnerListItem.studioName}`}
                      />
                    ))}
                  </Box>
                </Box>

                <Box className={classes.stickyBottom}>
                  <Divider className={classes.divider} />
                  <LineDivider verticalSpace={22} />
                  <Box>
                    <Box className={classes.navigationHeaderWrapper}>
                      <UserProfileIcon userAttr={userAttr} size='small' />
                      <DisplayTextAccent
                        textContent={formatMessage({
                          id: 'profile.dropdown.my_profile',
                          defaultMessage: 'User profile',
                        })}
                      />
                    </Box>
                    <ProfilePartnerItem
                      translate={false}
                      title={[userAttr?.given_name || 'N', userAttr?.name || 'A']
                        .filter(Boolean)
                        .join(' ')}
                      href={`/${params.language}/mein-benutzerkonto`}
                      isRectangle={false}
                      isActive
                    />
                  </Box>
                  <Divider className={classes.divider} />
                  <Box className={classes.logoutContainer}>
                    <Button
                      variant='text'
                      onClick={signOut}
                      className={classes.logoutBtnLink}
                      iconClassName={classes.logoutIcon}
                      icon={<LogoutIcon />}>
                      {formatMessage({ id: 'common.logout', defaultMessage: 'Logout' })}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default ProfileDropdown;
