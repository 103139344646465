import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { RootState } from '@/Store';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const Login = createAsyncThunk<
  CognitoUser,
  { username: string; password: string },
  { state: RootState }
>('auth/login', ({ username, password }) => Auth.signIn({ username, password }));
