import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../design/design-tokens.json';
import EmailConfirmed from './Components/EmailConfirmed';
import { Login } from './Components/Login';
import { GuestSwitchBtn } from './Components/Shared/Btns/GuestSwitchBtn';

const useStyles = makeStyles()(theme => ({
  root: {
    overflow: 'scroll',
    backgroundImage: `linear-gradient(170deg, ${FigmaDesignTokens.Blue[200]} 30%, ${theme.palette.primary.main} 108%)`,
    height: '100%',
  },
}));

export const EmailConfirmationPage = ({ emailConfirmed }: { emailConfirmed: boolean }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const location = useLocation() || '';
  const responce = location.search.includes('success')
    ? 'success'
    : location.search.includes('expired')
      ? 'expired'
      : location.search.includes('error')
        ? 'error'
        : '';

  return (
    <Grid className={classes.root}>
      <GuestSwitchBtn
        header={intl.formatMessage({
          id: 'view.register.header',
          defaultMessage: "You don't have an account for the Hansefit partner portal yet?",
        })}
        href='/login'
        btnText={intl.formatMessage({
          id: 'view.register.cta',
          defaultMessage: 'Register now',
        })}
      />
      {responce ? <EmailConfirmed responseType={responce} /> : <Login />}
    </Grid>
  );
};
