import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { getCourseTypes, getCourseTypeTranslation, getNewCoursePathname } from '@/Mappers';
import { Category, Course } from '@/Types';

import { CustomDataGrid } from '../../../../App/Shared/DataGridTable/Table/DataGridTable';
import { NoResultsOverlay } from '../../../../App/Shared/DataGridTable/Table/NoResultsOverlay';
import { NoRowsOverlay } from '../../../../App/Shared/DataGridTable/Table/NoRowsOverlay';
import { CourseTypeCell } from '../../../../App/Shared/Table/TableCell/CourseTypeCell';
import { DateCell } from '../../../../App/Shared/Table/TableCell/DateCell';
import { useTableRows } from '../../../../Hooks/useTableRows';
import { ActionsCell } from '../TableCell/ActionsCell';
import { CourseNameCell } from '../TableCell/CourseNameCell';
import { InternalCourseNameCell } from '../TableCell/InternalCourseNameCell';
import { MainCategoryCell } from '../TableCell/MainCategoryCell';
import { OpenEventsCell } from '../TableCell/OpenEventsCell';
import { PerformedEventsCell } from '../TableCell/PerformedEventsCell';
import { Toolbar } from '../Toolbar/Toolbar';

type CoursesTableProps = {
  courses: Course[];
  categories: Category[];
  search?: string;
  showAddNew?: boolean;
  showCourseSearch?: boolean;
  isPastCourse?: boolean;
  loading?: boolean;
};

export const CoursesTable = ({
  courses,
  categories,
  showCourseSearch,
  isPastCourse,
  loading,
}: CoursesTableProps) => {
  const intl = useIntl();

  const courseTypes = getCourseTypes();

  const currentLanguage = useCurrentLanguage();
  const navigate = useNavigate();

  const navigatetoNewCourseForm = useCallback(() => {
    navigate(getNewCoursePathname(currentLanguage));
  }, [navigate, currentLanguage]);

  const columns: GridColDef[] = [
    {
      field: 'levelImage',
      headerName: '',
      width: 68,
      hideSortIcons: true,
      filterable: false,
      sortable: false,
      resizable: false,
      display: 'flex',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Course, string>) => (
        <MainCategoryCell
          isPastCourse={!!isPastCourse}
          gridRenderCellParams={params}
          categories={categories}
        />
      ),
    },
    {
      field: 'courseName',
      headerName: intl.formatMessage({
        id: 'view.courses.current_courses.table.header.course_name',
        defaultMessage: 'Course name',
      }),
      width: 100,
      pinnable: false,
      display: 'flex',
      valueGetter: value => value[currentLanguage],
      renderCell: (params: GridRenderCellParams<Course, Course['courseName']>) => (
        <CourseNameCell gridRenderCellParams={params} />
      ),
    },
    {
      field: 'internalName',
      headerName: intl.formatMessage({
        id: 'view.courses.current_courses.table.header.internal_course_name',
        defaultMessage: 'Internal name',
      }),
      width: 160,
      display: 'flex',
      pinnable: false,
      renderCell: (params: GridRenderCellParams<Course, Course['internalName']>) => (
        <InternalCourseNameCell gridRenderCellParams={params} />
      ),
    },
    {
      field: 'courseType',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses.current_courses.table.header.course_type',
        defaultMessage: 'Course type',
      }),
      width: 90,
      pinnable: false,
      type: 'singleSelect',

      // TODO: Oh manomanoman
      valueGetter: (value: Course['courseType']) => getCourseTypeTranslation(value, intl),
      valueOptions: courseTypes.map(type => getCourseTypeTranslation(type.value, intl)),
      renderCell: (params: GridRenderCellParams<Course, Course['courseType']>) => (
        <CourseTypeCell value={params.value} />
      ),
    },
    {
      field: 'startDate',
      headerName: intl.formatMessage({
        id: 'view.courses.current_courses.table.header.start_date',
        defaultMessage: 'Start date',
      }),
      width: 110,
      pinnable: false,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<Course, Course['startDate']>) => (
        <DateCell value={params.value} />
      ),
    },
    {
      field: 'endDate',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses.current_courses.table.header.end_date',
        defaultMessage: 'End date',
      }),
      width: 100,
      pinnable: false,
      renderCell: (params: GridRenderCellParams<Course, Course['endDate']>) => (
        <DateCell value={params.value} />
      ),
    },
    {
      field: 'performedEvents',
      headerName: intl.formatMessage({
        id: 'view.courses.current_courses.table.header.performed_events',
        defaultMessage: 'Performed events',
      }),
      display: 'flex',
      width: 170,
      pinnable: false,
      headerClassName: 'hideColumnSeparator',
      renderCell: (params: GridRenderCellParams<Course, Course['performedEvents']>) => (
        <PerformedEventsCell value={params.value} />
      ),
    },
    {
      field: 'openEvents',
      headerName: intl.formatMessage({
        id: 'view.courses.current_courses.table.header.remaining_events',
        defaultMessage: 'Open events',
      }),
      width: 135,
      pinnable: false,
      display: 'flex',

      renderCell: (params: GridRenderCellParams<Course, Course['openEvents']>) => (
        <OpenEventsCell value={params.value} />
      ),
    },
    {
      field: 'nextEventDate',
      headerName: intl.formatMessage({
        id: 'view.courses.current_courses.table.header.next_event',
        defaultMessage: 'Next event',
      }),
      display: 'flex',
      width: 140,
      pinnable: false,
      headerClassName: 'hideColumnSeparator',
      renderCell: (params: GridRenderCellParams<Course, Course['nextEventDate']>) => (
        <DateCell value={params.value} />
      ),
    },
    {
      field: 'Actions',
      headerName: '',
      filterable: false,
      sortable: false,
      resizable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      headerClassName: 'hideColumnSeparator',
      width: 180,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<Course, Course>) => (
        <ActionsCell rowData={params.row} />
      ),
    },
  ];

  const rows = useTableRows({
    uniqueIdKey: 'courseId',
    data: courses || [],
  });

  return (
    <CustomDataGrid
      columnVisibilityModel={{
        openEvents: !isPastCourse,
        performedEvents: !!isPastCourse,
        nextEventDate: !isPastCourse,
      }}
      initialState={{ pinnedColumns: { right: ['actions'] } }}
      columns={columns}
      rows={rows}
      autoHeight
      rowHeight={66}
      pagination
      loading={loading}
      slots={{
        noResultsOverlay: () => (
          <NoResultsOverlay
            header={intl.formatMessage({
              id: 'view.courses_and_events.courses.table.empty_filter_header',
              defaultMessage: 'No Courses found',
            })}
            subHeader={intl.formatMessage({
              id: 'view.courses_and_events.courses.table.empty_filter_sub_header',
              defaultMessage: 'Please change your search criteria and try again.',
            })}
          />
        ),
        noRowsOverlay: () => (
          <NoRowsOverlay
            header={intl.formatMessage({
              id: 'common.empty_table.header',
              defaultMessage: 'You have not created any courses yet.',
            })}
            subHeader={intl.formatMessage({
              id: 'view.courses_and_events.events.table.empty_sub_header',
              defaultMessage:
                'You can enter your online courses directly via the Hansefit partner portal. Hansefit members see your online courses in the Hansefit app and can register directly via the app.',
            })}
            ctaTitle={intl.formatMessage({
              id: 'common.empty_table.cta',
              defaultMessage: 'create a new course',
            })}
            ctaHandler={navigatetoNewCourseForm}
          />
        ),
        toolbar: () => (
          <Toolbar
            {...{
              handleSubmit: navigatetoNewCourseForm,
              submitText: intl.formatMessage({
                id: 'table.toolbar.course.add_new_course',
                defaultMessage: 'Add new course',
              }),
              showSearch: showCourseSearch,
              searchFields: ['courseName', 'internalName'],
            }}
          />
        ),
      }}
    />
  );
};
