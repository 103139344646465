import { FormikHelpers, FormikProps } from 'formik';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { EquipmentsAndServicesForm, Header, PreviewModal, SpinnerLoader } from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import useEquipmentAndServiceBreadcrumbs from '@/Hooks/Breadcrumbs/useEquipmentAndServiceBreadcrumbs';
import { useCurrentPartnerIdQuery, useCurrentUserInfoQuery, useGetPartnerQuery } from '@/Queries';
import InfoIcon from '@/Static/Icons/info_delay_icon.svg';
import { EquipmentsAndServicesPartial } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

import { CtaFooter } from '../../App/Shared/Footer/CtaFooter';
import { DelayModal } from '../../App/Shared/Modal/DelayModal';
import { FormSnackbar } from '../../App/Shared/Notification/Components/FormSnackbar';

const EquipmentAndService = () => {
  const intl = useIntl();
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();
  const currentBreadcrumbs = useEquipmentAndServiceBreadcrumbs();
  const [equipmentAndServiceFormRef, setEquipmentAndServiceFormRef] =
    useState<FormikHelpers<EquipmentsAndServicesPartial>>();
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const [primaryCtaDisabled, setPrimaryCtaDisabled] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  const { data: partnerData, isLoading: isLoadingPartnerData } = useGetPartnerQuery();
  const { data: userAttributes, isLoading: isLoadingUserAttributes } = useCurrentUserInfoQuery();
  const { data: casPublicId, isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();

  const handleCloseToastMessage = useCallback(() => {
    setOpenToastMessage(false);
  }, []);

  const handleOpenToastMessage = useCallback(
    (openToastMessage: boolean) => {
      if (!delayModal.notShowProfileModal) {
        return;
      }
      setOpenToastMessage(openToastMessage);
    },
    [delayModal.notShowProfileModal],
  );

  const handlePrimaryCtaDisabled = useCallback((value: boolean) => {
    setPrimaryCtaDisabled(value);
  }, []);

  const receiveRef = useCallback(
    (ref: React.RefObject<FormikProps<EquipmentsAndServicesPartial> | undefined>) => {
      if (ref.current) {
        setEquipmentAndServiceFormRef(ref.current);
        setPrimaryCtaDisabled(true);
      }
    },
    [setEquipmentAndServiceFormRef],
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (equipmentAndServiceFormRef) {
        await equipmentAndServiceFormRef.submitForm();
      }
    } catch (error: unknown) {
      handleCatchError(error);
    }
  }, [equipmentAndServiceFormRef]);

  const handleShowPreview = useCallback(() => {
    setShowPreview(true);
  }, []);

  const handleClosePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const isLoading = isLoadingUserAttributes || isLoadingCasPublicId || isLoadingPartnerData;

  return (
    <>
      {isLoading && <SpinnerLoader isFullScreen />}
      <Header
        showLanguageSwitcher={false}
        callToActions={{
          primary: {
            title: intl.formatMessage({
              id: 'cta.save_and_publish',
              defaultMessage: 'Save & Publish',
            }),
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
          secondary: {
            title: intl.formatMessage({
              id: 'cta.preview_partner',
              defaultMessage: 'Preview Studio Profile',
            }),
            callback: handleShowPreview,
            disabled: false,
            variant: 'outlined',
          },
        }}
        header={intl.formatMessage({
          id: 'view.equipment_and_services',
          defaultMessage: 'Equipment & Services',
        })}
        breadcrumbs={currentBreadcrumbs}
      />
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={handleCloseToastMessage}
        customMessage={intl.formatMessage({
          id: 'toast.save.info',
          defaultMessage:
            'Die Änderungen werden über Nacht umgesetzt und sind am nächsten Tag erst sichtbar.',
        })}
        customIcon={InfoIcon}
      />
      {casPublicId && userAttributes?.attributes && partnerData && (
        <EquipmentsAndServicesForm
          casPublicId={casPublicId}
          partner={partnerData}
          refCallback={receiveRef}
          handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
          setOpenToastMessage={handleOpenToastMessage}
        />
      )}
      <CtaFooter
        callToActions={{
          primary: {
            title: intl.formatMessage({
              id: 'cta.save_and_publish',
              defaultMessage: 'Save & Publish',
            }),
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
        }}
      />
      {delayModal.open && (
        <DelayModal
          open
          title={intl.formatMessage({
            id: 'delayModal.title.info',
            defaultMessage: 'Verzögerte Sichtbarkeit',
          })}
          iconSrc={InfoIcon}
          onChecked={val => {
            dispatch({
              type: DelayModalTypes.SET_NOT_SHOW_PROFILE,
              payload: { notShowProfileModal: val },
            });
          }}
          content={intl.formatMessage({
            id: 'delayModal.info',
            defaultMessage:
              'Deine Änderungen im Hansefit Verbundpartner-Portal werden über Nacht umgesetzt. Somit sind die Änderungen erst am nächsten Tag in der Hansefit App oder auf der Website sichtbar.',
          })}
        />
      )}

      {showPreview && <PreviewModal onClose={handleClosePreview} />}
    </>
  );
};

export default EquipmentAndService;
