import { Grid } from '@mui/material';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { Dropzone, InfoMessage, UploadIndicator } from '@/Components';
import { ConfirmationDialogTypes, DelayModalTypes, useAppContext } from '@/Context';
import { getDefaultFilename } from '@/Mappers';
import {
  useDeleteAllAssetsMutation,
  useUpdateLogoAssetMutation,
  useUploadLogoMutation,
} from '@/Queries';
import { AssetServiceFactory } from '@/Services';
import { LogoAssetProps, Partner, Upload } from '@/Types';
import { CrazyUseRefCallbackStuffTypes, isLogoAssetRef } from '@/Utils/formRefTypeguards';
import handleCatchError from '@/Utils/handleCatchError';

import { AssetPreview } from './Assets/AssetPreview';

export type LogoFormProps = {
  casPublicId: Partner['casPublicId'];
  refCallback: (ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => void;
  partner?: Partner | null;
  logoAsset: LogoAssetProps;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  setOpenToastMessage: (value: boolean) => void;
  refetchAssets: () => void;
};

export const LogoForm = ({
  refCallback,
  casPublicId,
  logoAsset,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
  partner,
  refetchAssets,
}: LogoFormProps) => {
  const formikRef = useRef<FormikProps<LogoAssetProps>>(null);
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();
  const [initialState, setInitialState] = useState<LogoAssetProps>(logoAsset);
  const [uploads, setUploads] = useState<Upload[]>([]);
  const [deleteAssetIdQueue, setDeleteAssetIdQueue] = useState<number[]>([]);

  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);

  const { mutateAsync: uploadLogo } = useUploadLogoMutation();

  const { mutateAsync: deleteAllAssets } = useDeleteAllAssetsMutation();
  const { mutateAsync: updateLogoAsset } = useUpdateLogoAssetMutation();

  const handleCancel = useCallback((managedUpload: ManagedUpload) => {
    managedUpload.abort.bind(managedUpload);
    managedUpload.abort();
    setUploads([]);
  }, []);

  const handleDrop = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const file = files[0];

        const filename = getDefaultFilename(file.name);
        const managedUpload = await assetService.S3Upload(
          casPublicId,
          filename,
          'Logo',
          file,
          (progressEvent: ManagedUpload.Progress) => {
            const upload: Upload = {
              file: files[0],
              progress: progressEvent,
              managedUpload,
            };

            setUploads([upload]);
            handlePrimaryCtaDisabled(false);
          },
          async err => {
            if (err) {
              setUploads([
                {
                  file,
                  errorMessage: intl.formatMessage({
                    id: 'form.photo_and_video.logo.upload.error.failed',
                    defaultMessage: 'Upload failed',
                  }),
                },
              ]);
              return;
            }

            try {
              const response = await uploadLogo({
                casPublicId,
                filename,
                originalFilename: file.name,
              });

              formikRef.current?.setFieldValue('assetId', response.data.assetId);
              formikRef.current?.setFieldValue('uri', filename);
              formikRef.current?.setFieldValue('originalFilename', file.name);
              setUploads([]);
            } catch (error) {
              handleCatchError(error);
            }
          },
        );
      }
    },
    [assetService, casPublicId, handlePrimaryCtaDisabled, intl, uploadLogo],
  );

  const handleDeleteAsset = useCallback(
    (assetId: number) => {
      deleteAssetIdQueue.push(assetId);

      setDeleteAssetIdQueue([...deleteAssetIdQueue]);

      formikRef.current?.setFieldValue('assetId', 0);
      formikRef.current?.setFieldValue('uri', '');
      formikRef.current?.setFieldValue('originalFilename', '');
      handlePrimaryCtaDisabled(false);
    },
    [formikRef, deleteAssetIdQueue, setDeleteAssetIdQueue, handlePrimaryCtaDisabled],
  );

  useEffect(() => {
    if (isLogoAssetRef(formikRef)) {
      refCallback(formikRef, 0);
    }
  }, [refCallback, formikRef]);

  useEffect(() => {
    formikRef.current?.setValues(logoAsset);
    setInitialState(logoAsset);
    dispatch({
      type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
      payload: { show: false },
    });
    handlePrimaryCtaDisabled(true);
  }, [logoAsset, handlePrimaryCtaDisabled, dispatch]);

  const handleSubmit = async (values: LogoAssetProps) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    await deleteAllAssets({ casPublicId, deleteAssetIdQueue });

    const logoAsset: LogoAssetProps = {
      assetId: values.assetId === 0 ? null : values.assetId,
      uri: values.uri === '' ? null : values.uri,
      originalFilename: values.originalFilename,
    };

    try {
      await updateLogoAsset({ casPublicId, logoAsset });
      refetchAssets();

      setUploads([]);
      setDeleteAssetIdQueue([]);
      handlePrimaryCtaDisabled(true);
      setOpenToastMessage(true);
    } catch (error) {
      dispatch({
        type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
        payload: { show: true },
      });

      console.error('An error occurred:', error);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      validateOnBlur={false}
      validateOnChange
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          dispatch({
            type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
            payload: { show: true },
          });
          setInitialState(values);
          handlePrimaryCtaDisabled(false);
        }
      }}
      initialValues={logoAsset}
      onSubmit={handleSubmit}>
      {({ values }) => (
        <ContentContainer>
          <Form>
            <Grid container spacing={2}>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <FormHeader
                    title={intl.formatMessage({
                      id: 'view.photo_and_video.header.logo',
                      defaultMessage: 'Logo',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item xs={8}>
                <Grid container spacing={8}>
                  {!!values.assetId && values.uri && (
                    <Grid item xs={12}>
                      <AssetPreview
                        originalFilename={values.originalFilename}
                        assetId={values.assetId}
                        assetService={assetService}
                        casPublicId={casPublicId}
                        type='image'
                        handleDelete={handleDeleteAsset}
                        resource={values.uri}
                      />
                    </Grid>
                  )}
                  {!values.uri && uploads.length === 0 && (
                    <Grid item xs={12}>
                      <Dropzone
                        maxFileSize={15000000}
                        disabled={!!partner?.readonly}
                        supportedFileNames='JPEG, PNG, HEIC, TIFF'
                        onDrop={handleDrop}
                        accept='image/jpeg, image/png, image/heic, image/tiff'
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <UploadIndicator uploads={uploads} handleCancel={handleCancel} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} item>
                <Grid item xs={12}>
                  <InfoMessage
                    title={formatMessage({
                      id: 'form.photo_and_video.logo.hint.fileformats.header',
                      defaultMessage: 'Upload a logo',
                    })}
                    description={formatMessage({
                      id: 'form.photo_and_video.logo.hint.fileformats.description',
                      defaultMessage:
                        'Upload the logo of your institution here. The logo looks best when it has a transparent background.',
                    })}
                    type='information'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </ContentContainer>
      )}
    </Formik>
  );
};
