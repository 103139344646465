import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

export const useConfirmationStepStyles = makeStyles()(theme => ({
  mainContent: {
    width: 620,
    height: 'fit-content',
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '40px 36px',
    position: 'relative',
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainHeader: {
    marginTop: 10,
    marginBottom: 48,
    width: 391,
    color: FigmaDesignTokens.White,
  },
  headerIcon: {
    margin: '0 auto',
    marginBottom: 15,
  },
  headerForm: {
    color: FigmaDesignTokens.Blue[950],
    fontSize: 20,
    textAlign: 'center',
  },
  subHeader: {
    marginTop: 4,
    padding: '0 20px',
  },
  userBtn: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: theme.palette.background.default,
    height: 36,
    marginBottom: 40,
    padding: '7px 11px',
  },
  mainActionBtn: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    '&:disabled': {
      background: FigmaDesignTokens.Grey[200],
      color: theme.palette.info.dark,
    },
  },
  divider: {
    backgroundColor: FigmaDesignTokens.Grey[50],
    height: 2,
    marginTop: '32px',
    marginBottom: 28,
    width: '100%',
  },
  loginHeader: {
    textAlign: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 25,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: FigmaDesignTokens.Blue[950],
    marginBottom: '4px',
  },
}));

export const useInvitePartnerConfirmationStyles = makeStyles()(theme => ({
  mainContent: {
    width: 620,
    justifyContent: 'center',
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '20px 40px',
    paddingTop: 38,
    position: 'relative',
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: FigmaDesignTokens.Blue[950],
  },
  headerForm: {
    color: FigmaDesignTokens.Blue[950],
    fontSize: 20,
  },
  headerIcon: {
    margin: '0 auto',
    marginBottom: 15,
  },
  subHeader: {
    marginTop: 20,
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[950],
    textAlign: 'center',
  },
  changePartnerBtn: {
    marginTop: 38,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 7,
    paddingBottom: 7,
    fontSize: 12,
    letterSpacing: 0.34,
    color: theme.palette.background.default,
    borderRadius: 18,
  },
  stayOnCurrentPartnerBtn: {
    marginTop: 14,
    marginBottom: 68,
    paddingLeft: 11,
    paddingRight: 11,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 12,
    letterSpacing: 0.34,
    color: theme.palette.primary.dark,
    borderRadius: 18,
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

export const useValidatePartnerStepStyles = makeStyles()(theme => ({
  mainContent: {
    width: 620,
    height: 'fit-content',
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '20px 80px',
    paddingTop: 37,
    position: 'relative',
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
  userBtn: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: theme.palette.background.default,
    height: 36,
    marginBottom: 14,
    padding: '7px 21px',
  },
  mainActionBtn: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 20,
    marginBottom: 18,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    '&:disabled': {
      background: FigmaDesignTokens.Grey[200],
      color: theme.palette.info.dark,
    },
  },
  secondBtnWrapper: {
    width: 'auto',
    marginBottom: '16px',
  },
  secondBtn: {
    color: theme.palette.primary.dark,
  },
  secondBtnHeader: {
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 2,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[700],
    marginTop: '6px',
  },
  error: {
    color: theme.palette.error.main,
    textAlign: 'center',
    marginTop: 8,
  },
  textField: {
    width: 200,
    '& input.MuiOutlinedInput-input::placeholder': {
      color: `${theme.palette.info.dark}!important`,
    },
    '& label, input': {
      fontSize: 14,
    },
    '& label.Mui-error': {
      fontSize: 14,
    },
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  startCodeField: {
    width: 62,
  },
  fieldTitle: {
    marginBottom: 8,
  },
  multiInputsWrapper: {
    margin: '0 55px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  partKeyDash: {
    margin: '0 10px',
    color: FigmaDesignTokens.Grey[950],
    lineHeight: 1.43,
    letterSpacing: 0.18,
  },
  legalHint: {
    fontSize: 10,
    textAlign: 'center',
    paddingLeft: 90,
    paddingRight: 90,
    lineHeight: 1.5,
    marginTop: 0,
    '& a': {
      textStyle: 'underline',
      fontWeight: 'bold',
      color: FigmaDesignTokens.Grey[700],
    },
  },
  inputBoxWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 4,
  },
  closeButtonWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
}));

export const useUserFormStyles = makeStyles()(theme => ({
  mainContent: {
    width: 620,
    height: 'fit-content',
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '20px 76px',
    position: 'relative',
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
  subHeader: {
    marginTop: 16,
  },
  userBtn: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: theme.palette.background.default,
    height: 36,
    marginBottom: '40px',
    padding: '7px 11px',
  },
  mainActionBtn: {
    marginTop: '10px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    '&:disabled': {
      background: FigmaDesignTokens.Grey[200],
      color: theme.palette.info.dark,
    },
  },
  error: {
    fontSize: 14,
    color: theme.palette.error.main,
    textAlign: 'center',
    lineHeight: 1.43,
    letterSpacing: 0.18,
  },
  textField: {
    '& .MuiOutlinedInput-root ': {
      height: '53.63px',
    },
    '& label, input': {
      fontSize: 14,
    },
    '& label.Mui-error': {
      fontSize: 14,
    },
    marginBottom: 2,
    marginTop: 4,
  },
  formContainer: {
    paddingTop: 0,
  },
  legalHint: {
    fontSize: 10,
    textAlign: 'center',
    paddingLeft: 90,
    paddingRight: 90,
    lineHeight: 1.5,
    marginTop: 0,
    '& a': {
      textStyle: 'underline',
      fontWeight: 'bold',
      color: FigmaDesignTokens.Grey[700],
    },
  },
}));
