import { Box, Snackbar, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import Check from '@/Static/Icons/atoms-symbols-icons-check.svg';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

type FormSnackbarProps = {
  handleCloseToastMessage: () => void;
  openToastMessage: boolean;
  customMessage?: string;
  customIcon?: string;
};

const useStyles = makeStyles()(() => ({
  snackbarWrapper: {
    height: 40,
    padding: '10px 18px 10px 17px',
    backgroundColor: FigmaDesignTokens.White,
    color: FigmaDesignTokens.Grey[950],
    borderRadius: 18,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
  },
  snackbarText: {
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 0.11,
  },
  snackbarIcon: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
}));

export const FormSnackbar = ({
  handleCloseToastMessage,
  openToastMessage,
  customMessage,
  customIcon = Check,
}: FormSnackbarProps) => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <Snackbar
      open={openToastMessage}
      autoHideDuration={2000}
      onClose={handleCloseToastMessage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Box className={classes.snackbarWrapper}>
        <img alt='' src={customIcon} className={classes.snackbarIcon} />
        <Typography className={classes.snackbarText}>
          {customMessage
            ? customMessage
            : intl.formatMessage({ id: 'Änderungen erfolgreich gespeichert' })}
        </Typography>
      </Box>
    </Snackbar>
  );
};
