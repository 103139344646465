import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { BodyTextSmall, MainHeadline } from '@/Components';
import Check from '@/Static/Icons/atoms-symbols-icons-check.svg';

import { LoginPhotos } from '../../Login/LoginPhotos';
import { SignInForm } from '../../Shared/SignInForm';
import { useConfirmationStepStyles } from './Steps.styles';

export interface ConfirmationStepProps {
  email?: string;
  password?: string;
  showHeader: boolean;
  isEmailConfirmed?: boolean;
}

export const ConfirmationStep = ({
  email,
  password,
  showHeader,
  isEmailConfirmed,
}: ConfirmationStepProps) => {
  const { classes } = useConfirmationStepStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      {showHeader && (
        <MainHeadline
          className={clsx(classes.mainHeader)}
          textAlign='center'
          textContent={formatMessage({
            id: 'app.brand_with_alternative_name',
            defaultMessage: 'Hansefit Verbundpartner-portal',
          })}
        />
      )}

      <Box className={classes.mainContent}>
        <img alt='' src={Check} className={classes.headerIcon} />
        <MainHeadline
          className={clsx(classes.headerForm)}
          textAlign='center'
          textContent={formatMessage({
            id: 'form.register.stepper.step.header',
            defaultMessage: 'That worked!',
          })}
        />
        {!isEmailConfirmed && (
          <BodyTextSmall
            textAlign='center'
            textContent={formatMessage({
              id: 'form.register.stepper.step.email_sent',
              defaultMessage:
                'Please verify your email address by clicking on the link. It should appear in your mailbox shortly',
            })}
            className={classes.subHeader}
          />
        )}
        {isEmailConfirmed && (
          <>
            <BodyTextSmall
              textAlign='center'
              textContent={formatMessage({
                id: 'form.register.stepper.step.subheader',
                defaultMessage:
                  'Your registration for the Verbundpartner portal is now complete. Start now directly and complete your profile details.',
              })}
              className={classes.subHeader}
            />

            <Box className={classes.divider} />

            <Typography variant='body1' className={classes.loginHeader}>
              {formatMessage({ id: 'form.register.stepper.step.cta', defaultMessage: 'Login' })}
            </Typography>

            <SignInForm variant={2} email={email} password={password} />
          </>
        )}
        <LoginPhotos />
      </Box>
    </>
  );
};
