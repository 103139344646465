import { CognitoUser } from 'amazon-cognito-identity-js';

export enum DataFetchingStatus {
  Idle,
  Fetching,
  HasError,
  FulFilled,
}

export interface AuthStateTypes {
  isAuthenticated: boolean;
  fetchingStatus: DataFetchingStatus;
  credentials: { email: string; password: string } | null;
  partnerRoles: { [key: string]: string }[];
  cognitoUser?: CognitoUser;
}
