import { Grid } from '@mui/material';
import { ConnectedFocusError } from 'focus-formik-error';
import { Form, Formik, FormikProps } from 'formik';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { CustomizedAutocomplete } from '@/App/Shared/Form/Components/Customized/CustomizedAutocomplete';
import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import { Categories, InfoMessage, SelectedCategories, SelectedTags } from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import { useUpdatePartnerDataMutation } from '@/Queries';
import { CategoriesAndTagsPartial, Category, Partner, Tag } from '@/Types';
import { PartnerCategoriesValidation } from '@/Utils';
import handleCatchError from '@/Utils/handleCatchError';

type PartnerCategoriesFormProps = {
  casPublicId: Partner['casPublicId'];
  categories: Category[];
  refCallback: (
    ref: React.RefObject<FormikProps<CategoriesAndTagsPartial> | undefined>,
    tabNumber: number,
  ) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  setOpenToastMessage: (value: boolean) => void;
  partner: Partner;
};

export const PartnerCategoriesForm = ({
  categories,
  refCallback,
  casPublicId,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
  partner,
}: PartnerCategoriesFormProps) => {
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();

  const formikRef = useRef<FormikProps<CategoriesAndTagsPartial>>(null);
  const intl = useIntl();
  const initialState = useMemo<CategoriesAndTagsPartial>(
    () => ({
      categoryPrimary: partner.categoryPrimary,
      categories: partner.categories ? partner.categories : [],
      tags: partner.tags,
    }),
    [partner],
  );
  const handleConfirmDialog = useConfirmDialog();
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState<number | null>(
    partner.categoryPrimary,
  );
  const [selectedCategories, setSelectedCategories] = useState<number[]>(
    partner.categories ? partner.categories : [],
  );

  const [selectedTags, setSelectedTags] = useState<Tag[]>(
    !!partner.tags
      ? partner.tags.split(',').map((tag, idx) => ({ id: idx.toString(), title: tag }))
      : [],
  );

  const { mutateAsync: updatePartnerData } = useUpdatePartnerDataMutation();

  useEffect(() => {
    refCallback(formikRef, 2);
  }, [refCallback, formikRef]);

  const handlePrimaryCategoryDelete = useCallback(() => {
    setSelectedPrimaryCategory(null);
    formikRef.current?.setFieldValue('categoryPrimary', null);
  }, [setSelectedPrimaryCategory]);

  const handleCategoryDelete = useCallback(
    (category: Category) => {
      const filtered = selectedCategories.filter(
        selectedCategoryId => selectedCategoryId !== parseInt(category.id, 10),
      );
      setSelectedCategories(filtered);
      formikRef.current?.setFieldValue('categories', filtered);
    },
    [selectedCategories],
  );

  const handleCategorySelect = useCallback(
    (id: string) => {
      const category = categories.find(category => category.id === id);
      if (category) {
        const updatedSelectedCategories = [...selectedCategories, parseInt(category.id, 10)];
        setSelectedCategories(updatedSelectedCategories);
        formikRef.current?.setFieldValue('categories', updatedSelectedCategories);
      }
    },

    [selectedCategories, categories, setSelectedCategories],
  );

  const handleEnterTag = useCallback(
    (enteredTag: string) => {
      if (enteredTag.length > 0) {
        const updatedTags = [
          ...selectedTags,
          ...enteredTag.split(',').map(tag => ({ id: nanoid(10), title: tag })),
        ];

        setSelectedTags(updatedTags);

        formikRef.current?.setFieldValue('tags', updatedTags.map(tag => tag.title).join(','));
      }
    },
    [selectedTags, setSelectedTags, formikRef],
  );

  const handleTagDelete = useCallback(
    (tag: Tag) => {
      const filtered = selectedTags.filter(selectedTag => selectedTag.id !== tag.id);

      setSelectedTags(filtered);
      formikRef.current?.setFieldValue('tags', filtered.map(tag => tag.title).join(','));
    },
    [selectedTags],
  );

  const categoryIds = useMemo(() => {
    if (selectedCategories.length >= 6) {
      return categories.map(category => parseInt(category.id));
    } else {
      return [];
    }
  }, [categories, selectedCategories]);

  const handleSubmit = async (updatedData: CategoriesAndTagsPartial) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    try {
      await updatePartnerData({ casPublicId, updatedData });

      handlePrimaryCtaDisabled(true);
      handleConfirmDialog(false);
      setOpenToastMessage(true);
    } catch (error) {
      handleCatchError(error);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialState}
      validationSchema={() => PartnerCategoriesValidation(intl)}
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          handleConfirmDialog(true);
          handlePrimaryCtaDisabled(false);
        }
      }}
      onSubmit={handleSubmit}>
      {() => (
        <Form>
          <ConnectedFocusError />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormHeader
                      title={intl.formatMessage({
                        id: 'form.partner_categories.header.partner_category',
                        defaultMessage: 'Partner category',
                      })}
                    />
                  </Grid>

                  <Grid xs={8} item>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SubHeader
                          title={intl.formatMessage({
                            id: 'form.partner_categories.header.chosen_category',
                            defaultMessage: 'Please check your preselected category.',
                          })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectedCategories
                          disabled
                          selectedCategories={
                            selectedPrimaryCategory !== null ? [selectedPrimaryCategory] : []
                          }
                          categories={categories}
                          handleDelete={handlePrimaryCategoryDelete}
                          name='categoryPrimary'
                        />
                        <FormikErrorMessage name='categoryPrimary' />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ContentContainer>
            </Grid>

            <Grid item xs={12}>
              <ContentContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormHeader
                      title={intl.formatMessage({
                        id: 'form.partner_categories.header.additional_matching_categories',
                        defaultMessage: 'More suitable categories',
                      })}
                    />
                  </Grid>
                  <Grid xs={8} item>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SubHeader
                          title={intl.formatMessage({
                            id: 'form.partner_categories.header.chosen_categories',
                            defaultMessage: 'You can select multiple categories.',
                          })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectedCategories
                          disabled={partner.readonly}
                          selectedCategories={selectedCategories}
                          categories={categories}
                          /** @ts-ignore **/
                          handleDelete={handleCategoryDelete}
                          name='categories'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Categories
                          multiSelect
                          selectedCategories={selectedCategories}
                          handleClick={handleCategorySelect}
                          categories={categories}
                          disabledIds={
                            selectedPrimaryCategory
                              ? [selectedPrimaryCategory, ...categoryIds]
                              : [0]
                          }
                          name='categories'
                          readOnly={partner?.readonly}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={4} item>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <InfoMessage
                          title={intl.formatMessage({
                            id: 'form.partner_categories.hint.partner_categories.header',
                            defaultMessage: 'Choose other suitable categories',
                          })}
                          description={intl.formatMessage({
                            id: 'form.partner_categories.hint.partner_categories.description',
                            defaultMessage:
                              'Select other suitable categories so that your facility can be found more easily via the search.',
                          })}
                          type='information'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ContentContainer>
            </Grid>

            <Grid item xs={12}>
              <ContentContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormHeader
                      title={intl.formatMessage({
                        id: 'form.partner_categories.header.tags',
                        defaultMessage: 'Tags',
                      })}
                    />
                  </Grid>
                  <Grid xs={8} item>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <CustomizedAutocomplete
                          handleEnterTag={handleEnterTag}
                          ctaTitle={intl.formatMessage({
                            id: 'form.partner_categories.tags.cta',
                            defaultMessage: 'add',
                          })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectedTags tags={selectedTags} handleDelete={handleTagDelete} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={4} item>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <InfoMessage
                          title={intl.formatMessage({
                            id: 'form.partner_categories.hint.tags.header',
                            defaultMessage: 'Use tags to be found better',
                          })}
                          description={intl.formatMessage({
                            id: 'form.partner_categories.hint.tags.description',
                            defaultMessage:
                              'You offer special services that are not covered by the categories? Then use the tags to be found better in the search.',
                          })}
                          type='information'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ContentContainer>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
